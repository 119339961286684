import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Page404 from './Page404';
import Projects from './components/portfolio/projects/Projects';
import Nav from './components/Nav';
import Footer from './components/Footer';
import Landing from './components/portfolio/landing/Landing';
import Resume from './components/portfolio/resume/Resume';
import ContactMe from './components/portfolio/contact/ContactMe';


const App: React.FC = () => {
  return (
    <div className="container">
      <Nav />
      <Router>
        <Routes>
          <Route path="/" element={ <Landing /> } />
          <Route path="/projects" element={ <Projects /> } />
          <Route path="/resume" element={<Resume />} />
          <Route path="/contact" element={< ContactMe />} />
          <Route path="*" element={ <Page404 /> } />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
