import React from 'react';
import me from '../../../data/me'

const Landing: React.FC = () => {
  return (
    <div className="landing">
      <div className="landing-inner">
        <div className="section">
          <div className="tag"><span>about:</span></div>
          <div className="about">
            Hello, I'm Ernesto, a fullstack software engineer based in San Diego, CA, with a focus on building scalable and reliable systems. Over the years, I've had the opportunity to work across diverse environments, from established companies to fast-paced startups and early-stage ventures. Feel free to explore my <a href={'/projects'}><u>projects</u></a> or reach out to <a href={'/contact'}><u>connect</u></a>!
          </div>
        </div>
        <div className="section">
          <div className="tag"><span>languages:</span></div>
          <div className="languages">
            {me.technologies.languages.map((language, index) => (
              <span key={index}>{language}{index !== me.technologies.languages.length - 1? ",": "" }</span>
            ))}
          </div>
        </div>
        <div className="section">
          <div className="tag"><span>tools:</span></div>
          <div className="tools">
            {me.technologies.tools.map((tool, index) => (
              <span key={index}>{tool}{index !== me.technologies.tools.length - 1? ",": "" }</span>
            ))}
          </div>
        </div>
      </div>
      {/* <div className="scroll-down"></div> */}
    </div>
  );
};

export default Landing;