const me = {
  github: "https://www.github.com/ernstgodoy",
  linkedin: "https://www.linkedin.com/in/ernestogodoy",
  email: "mailto:ernsto.godoy@gmail.com",
  about: "",
  technologies: {
    languages: ["ruby", "java", "sql", "typescript", "javascript", "html", "css/scss"],
    tools: ["rails", "spring boot", "react", "angular", "git", "chatgpt"]
  },

}

export default me