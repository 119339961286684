import React from 'react';

const Resume: React.FC = () => {
    const resume = require('../../../assets/documents/Ernesto_Godoy_Pub.pdf');

    return (
        <embed 
            src={resume} 
            type="application/pdf" 
            className="resume"
        />
    );
}

export default Resume;