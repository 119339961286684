import React from 'react';
import { FiLink, FiGithub } from 'react-icons/fi'
import projectData from '../../../data/projectData'

const Projects: React.FC = () => {
  return (
    <div className="projects">
      <h1>Projects</h1>
      <div className="line"></div>
      { projectData.map((p: any, i) => {
        return (
          <div key={ i } className={ i%2===0 ? "project-even" : "project-odd" }>
            <div className="project-inner">
              <h1>{p.name}</h1>
              <div className="img-wrapper">
                <img src={ p.img } alt="" />
              </div>
              <div className="description">
                <div className="stack">
                  { p.stack.map((s: any, j: number) => {
                    return ( 
                      <span key={ j }>{ s }</span>
                    )
                  })}
                </div>
                <div className="description-links">
                  <a 
                    href={ p.repo || "#" } 
                    target={p.repo ? "_blank" : undefined} 
                    rel={p.repo ? "noopener noreferrer" : undefined} 
                    className={!p.repo ? "disabled" : ""}
                  >
                    <FiGithub />
                  </a>
                  <a 
                    href={ p.demo || "#" } 
                    target={p.demo ? "_blank" : undefined} 
                    rel={p.demo ? "noopener noreferrer" : undefined} 
                    className={!p.demo ? "disabled" : ""}
                  >
                    <FiLink />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  );
};

export default Projects;